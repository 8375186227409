<template>
  <div class="personal-center-container">
    <el-card shadow="hover">
      <el-tabs v-model="activeName">
        <el-tab-pane label="账户信息" name="link_tel">
          <el-col :lg="12" :md="16" :sm="24" :xl="12" :xs="24">
            <el-form ref="form" label-width="80px" :model="form" :rules="rules">
              <el-form-item label="旧密码" prop="old_password">
                <el-input v-model="form.old_password" />
              </el-form-item>
              <el-form-item label="新密码" prop="password">
                <el-input v-model="form.password" />
              </el-form-item>
              <el-form-item label="确认密码" prop="again_password">
                <el-input v-model="form.again_password" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">保存</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { resetDataItem } from "@/project/eztree/baseApi/commonApi";
import { isPassword, isPhone } from "@/utils/validate";
export default {
  name: "PersonalCenter",
  components: {},
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!isPassword(value)) {
        callback(new Error("密码不能少于6位"));
      }
      if (this.form.password != this.form.again_password) {
        callback(new Error("新密码和确认密码不一致"));
      }
      callback();
    };
    return {
      activeName: "link_tel",
      form: {
        old_password: "",
        password: "",
        again_password: "",
      },
      result: {},
      rules: {
        old_password: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
          { validator: validatePassword, trigger: "blur" },
        ],
        again_password: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
          { validator: validatePassword, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      avatar: "user/avatar",
    }),
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        console.log(this.form);
        if (valid) {
          if (this.form.password != this.form.again_password) {
            this.$baseMessage("新密码和确认密码不一致", "error", "vab-hey-message-error");
            return false;
          }
          resetDataItem(this.form).then(({ code, data }) => {
            if (code == "200") {
              this.$baseMessage("操作成功", "success", "vab-hey-message-success");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$base: ".personal-center";
#{$base}-container {
  padding: 0 !important;
  background: $base-color-background !important;

  #{$base}-user-info {
    padding: $base-padding;
    text-align: center;

    ::v-deep {
      .el-avatar {
        img {
          cursor: pointer;
        }
      }
    }

    &-full-name {
      margin-top: 15px;
      font-size: 24px;
      font-weight: 500;
      color: #262626;
    }

    &-description {
      margin-top: 8px;
    }

    &-follow {
      margin-top: 15px;
    }

    &-list {
      margin-top: 18px;
      line-height: 30px;
      text-align: left;
      list-style: none;

      h5 {
        margin: -20px 0 5px 0;
      }

      ::v-deep {
        .el-tag {
          margin-right: 10px !important;
        }

        .el-tag + .el-tag {
          margin-left: 0;
        }
      }
    }
  }

  #{$base}-item {
    display: flex;

    i {
      font-size: 40px;
    }

    &-content {
      box-sizing: border-box;
      flex: 1;
      margin-left: $base-margin;

      &-second {
        margin-top: 8px;
      }
    }
  }
}
</style>
